import React, { useEffect, useReducer } from 'react'
import { Table, Typography, Button, message, Alert } from 'antd'
import { formatDate } from '../../helpers/time'
import { useRenewLogs, useStartPayment } from '../../api/administrator'
import { simpleReducer } from '../../helpers'
import ListTool from '../../Components/Profile/ListTool'
import { appConfig } from '../../constants/appConfig'

const { Title } = Typography

function Main() {
  const initialState = {
    fromDate: null,
    toDate: null,
    paymentResponse: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const { data } = useRenewLogs({
    fromDate: state.fromDate?.format(appConfig.dateFormats.apiDateTimeT3),
    toDate: state.toDate?.format(appConfig.dateFormats.apiDateTimeAllDay),
  })

  const {
    data: dataPayment,
    isSuccess: isSuccessPayment,
    isError: isErrorPayment,
    isLoading: isLoadingPayment,
    mutate: mutatePayment,
  } = useStartPayment()

  useEffect(() => {
    if (isSuccessPayment && dataPayment) {
      setState({ paymentResponse: JSON.stringify(dataPayment?.data) })
    } else if (isErrorPayment) {
      setState({ paymentResponse: JSON.stringify(dataPayment?.data) })
      message.error('Ошибка выполнения запроса запуска рекурентных платежей')
    }
  }, [dataPayment, isSuccessPayment, isErrorPayment])

  const handleRange = (value) => {
    if (value) {
      const [fromDate, toDate] = value
      setState({ fromDate, toDate })
    } else {
      setState({ fromDate: null, toDate: null })
    }
  }

  const handleStartPayment = () => {
    mutatePayment({
      data: { addDays: -1, influencers: [] },
    })
  }

  const columns = [
    {
      title: 'Дата',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (createDate) => {
        return <>{formatDate(createDate)}</>
      },
    },
    {
      title: 'Инфлюенсер',
      dataIndex: 'influencerNickName',
      key: 'influencerNickName',
    },
    {
      title: '1 мес',
      dataIndex: 'success1Month',
      key: 'success1Month',
    },
    {
      title: '3 мес',
      dataIndex: 'success3Month',
      key: 'success3Month',
    },
    {
      title: '12 мес',
      dataIndex: 'success12Month',
      key: 'success12Month',
    },
    {
      title: 'Не выполненые',
      dataIndex: 'recurrentLeft',
      key: 'recurrentLeft',
    },
    {
      title: 'Выключен рекуррент',
      dataIndex: 'nonRecurrentLeft',
      key: 'nonRecurrentLeft',
    },
  ]
  return (
    <div className="users">
      <Title level={3}>Выполненные рекуррентные платежи</Title>
      <Button
        onClick={handleStartPayment}
        style={{ marginBottom: '1rem' }}
        loading={isLoadingPayment}
      >
        Запуск рекурентных платежей
      </Button>
      {state.paymentResponse && (
        <Alert
          message={state.paymentResponse}
          closable
          type="info"
          style={{ marginBottom: '1rem' }}
        />
      )}
      <ListTool onChangeRange={handleRange} />
      <Table pagination={false} columns={columns} dataSource={data?.data || []} />
    </div>
  )
}

export default Main
